import React from 'react'
import Home from './Pages/Home';
import { Suspense, lazy } from 'react';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
} from "react-router-dom";
import ScrollToTop from './Components/ScrollToTop';
const Services = React.lazy(() => import('./Pages/Services'))
const About = React.lazy(() => import('./Pages/About'))
const Team = React.lazy(() => import('./Pages/Team'))
const Gallery = React.lazy(() => import('./Pages/Gallery'))
const Contact = React.lazy(() => import('./Pages/Contact'))
const Footer = React.lazy(() => import('./Pages/Footer'))

const Main = () => {
    return (
        <div className=' bg-black'>
            <Home />
            <Suspense fallback={<div>Loading...</div>}>
                <About />
                <Services />
                <Team />
                <Gallery />
                <Contact />
                <Footer />
            </Suspense>
            <ScrollToTop />
        </div>
    )
}

export default Main





